import { render, staticRenderFns } from "./Proforma.vue?vue&type=template&id=1ef52a85&"
import script from "./Proforma.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Proforma.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports