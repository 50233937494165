// import ProformaList from "../components/ProformaList.vue";
import ProformaItem from '../components/Proforma.vue';
import ProformaList from '../components/ProformaList.vue';

const proformaRoutes = [
  {
    path: 'proforma',
    name: 'proformaList',
    props: true,
    // props: ({params}) => ({callId:+params.callId}),
    component: () => import('../components/ProformaLayout.vue'),
    children: [
      {
        name: 'proformaList',
        path: '',
        props: true,
        // props: ({params}) => ({callId:+params.callId}),
        component: ProformaList,
      },
      {
        name: 'proformaItem',
        path: ':proformaId',
        props: true,

        component: ProformaItem,
      },
    ],
  },
];

export default proformaRoutes;
