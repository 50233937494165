
import { defineComponent, PropType } from 'vue';

interface ProformaLine {
  ID: number; // Unique identifier for the item
  GROUP_TEXT: string; // Describes the group (e.g., "Port dues")
  PRICE_TEXT: string; // Describes the price item (e.g., "Pilotage in")
  MATRIX_X_CRITERIA: string; // X-axis criteria (can be empty)
  MATRIX_Y_CRITERIA: string; // Y-axis criteria (can be empty)
  QUANTITY_IN: number; // Quantity for "in" items
  QUANTITY_LABEL: string; // Optional label for the quantity (can be empty)
  RATE: number; // Rate for the item
  Currency: string; // Currency of the item
  Total: number;
  MULTIPLE: number; // Multiplier for the item
  SubTotal: number; // Calculated subtotal (QUANTITY_IN * RATE * MULTIPLE)
  REBATE_IN: number; // Rebate percentage (negative value)
  REBATE_AMOUNT: number; // Calculated rebate amount
  VatPercent: number; // VAT percentage
  VAT: number; // Calculated VAT amount
  AMOUNT_IN: number; // Total amount after rebate and VAT
  COMMENTS: string; // Optional comments (can be empty)
  TARIFF_DESCRIPTION: string; // Description of the tariff (can be empty)
  SORT_ORDER: number; // Sorting order for the item
  PGID: number; // Price group ID
  pgIndex: number; // Index of the price group (-1 as default)
  MANUAL: number; // Indicates if the item was entered manually (1 = true)
  DISCOUNT_FROM_TARIFF: number; // Indicates a discount from the tariff
  SPECIAL_AGREEMENT_COMMENT: string; // Optional special agreement comments (can be empty)
}

interface Currency {
  currencyName: string;
  rate: number;
}

export default defineComponent({
  props: {
    lines: { type: Object as PropType<ProformaLine[]>, required: true },
    currency: { type: Object as PropType<Currency[]> },
    LOCAL_CURRENCY: { type: String },
  },
  computed: {
    columns() {
      const result = [
        { header: 'Description', field: 'PRICE_TEXT', dataType: 'string' },
        { header: 'QTY', field: 'QUANTITY_IN', dataType: 'number', decimals: 2 },
        { header: 'Unit', field: 'QUANTITY_LABEL', dataType: 'string' },
        { header: 'Price', field: 'RATE', dataType: 'number', decimals: 2 },
        { header: 'Currency', field: 'Currency', dataType: 'string' },
        {
          header: 'Multiple',
          field: 'MULTIPLE',
          calculated: (rowdata: ProformaLine) => {
            if (rowdata.MULTIPLE == 1) {
              return '';
            }
            return rowdata.MULTIPLE;
          },
        },
        { header: 'Sub Total', field: 'SubTotal', dataType: 'number', decimals: 2 },
        {
          header: 'Rebate In',
          field: 'REBATE_IN',
          decimals: 2,
          calculated: (rowdata: ProformaLine) => {
            if (rowdata.REBATE_IN) {
              if (rowdata.REBATE_IN > 0) {
                return `+${rowdata.REBATE_IN}%`;
              }
              return `${rowdata.REBATE_IN}%`;
            }
            return '';
          },
        },
        {
          header: 'Rebate Amount',
          field: 'REBATE_AMOUNT',
          decimals: 2,
          calculated: (rowdata: ProformaLine) => {
            if (rowdata.REBATE_AMOUNT) {
              if (rowdata.REBATE_AMOUNT > 0) {
                return `+${rowdata.REBATE_AMOUNT}`;
              }
              return `${rowdata.REBATE_AMOUNT}`;
            }
            return '';
          },
        },
        {
          header: 'VAT Percent',
          field: 'VatPercent',
          calculated: (rowdata: ProformaLine) => {
            if (rowdata.VatPercent) {
              if (rowdata.VatPercent > 0) {
                return `+${rowdata.VatPercent}%`;
              }
              return `${rowdata.VatPercent}%`;
            }
            return '';
          },
        },
        {
          header: 'VAT',
          field: 'VAT',
          decimals: 2,
          calculated: (rowdata: ProformaLine) => {
            if (rowdata.VAT) {
              if (rowdata.VAT > 0) {
                return `+${rowdata.VAT}`;
              }
              return `${rowdata.VAT}`;
            }
            return '';
          },
        },
        {
          header: `Total ${this.LOCAL_CURRENCY}`,
          field: 'Total',
          decimals: 2,
          calculated: (rowdata: ProformaLine) => {
            if (rowdata.Currency != this.LOCAL_CURRENCY) {
              return `${rowdata.Total}`;
            }
            return `${rowdata.Total}`;
          },
        },
      ];
      if (this.currency) {
        this.currency.forEach((element) => {
          result.push({
            header: element.currencyName,
            field: element.currencyName,
            calculated: (rowdata: ProformaLine) => {
              if (element.rate && element.currencyName != rowdata.Currency) {
                return (rowdata.Total / element.rate).toFixed(2);
              }
              return (rowdata.SubTotal + rowdata.REBATE_AMOUNT).toFixed(2);
            },
          });
        });
      }
      result.push(
        { header: 'Comments', field: 'COMMENTS', dataType: 'string' },
        { header: 'Tariff Description', field: 'TARIFF_DESCRIPTION', dataType: 'string' }
      );
      return result;
    },
  },
});
